import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sfco-section-dos',
  templateUrl: './sfco-section-dos.component.html',
  styleUrls: ['./sfco-section-dos.component.css']
})
export class SfcoSectionDosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
