import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sfco-section-cinco',
  templateUrl: './sfco-section-cinco.component.html',
  styleUrls: ['./sfco-section-cinco.component.css']
})
export class SfcoSectionCincoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
