<section class="container" id="projects">
    <div class="container-title">
        <h1>{{'PROJECTS' | translate}}</h1>
        <p>{{'ABOUT_PROJECTS' | translate}}</p>
    </div>
    <div class="container-card">
        <div>
            <h2>CELLVOZ</h2>
            <span>{{'DEVELOPMENT' | translate}}</span>
            <p>{{'ABOUT_CELLVOZ' | translate}}</p>
            <a href="https://www.cellvoz.com">{{'LINK_TO' | translate}}</a> 
        </div>
        <div class="container-card-especial">
            <h2>VIGPRO</h2>
            <span>{{'DEVELOPMENT' | translate}}</span>
            <p>{{'ABOUT_VIGPRO' | translate}}</p>
            <a href="https://www.vigpro.com">{{'LINK_TO' | translate}}</a>
        </div>
        <div>
            <h2>ICannRX</h2>
            <span>{{ 'STARTUP_PLAN' | translate}}</span>
            <p>{{'ABOUT_ICANNRX' | translate}}</p>
            <a href="https://www.fabioarias.co">{{'LINK_TO' | translate}}</a>
        </div>
    </div>
</section>