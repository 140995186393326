<header class="container" id="home">
    <div>
        <nav class="nav">
            <div class="logo">
                <img src="/assets/LOGOSSFCO/1x/Asset 3.png" alt="imagine it. code it.">
            </div>
            <div class="menu">
                <a href="#home">{{'HOME' | translate}}</a>
                <a href="#team">{{'TEAM' | translate}}</a>
                <a href="#projects">{{'PROJECTS' | translate}}</a>
                <a href="#contact">{{'CONTACT' | translate}}</a>
            </div>
        </nav>
        <section class="header-section">
            <h1>{{ 'TEXT_HEADER_1' | translate}}</h1>
            <p>{{'SUMMARY_DEV' | translate}}</p>
        </section>
        <div class="svg">
            <img src="assets/img-sfco/ilustracion-header.png" alt="img" width="400px" class="header-img">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" >
                <path fill="#ffffff"  d="M0,0L11.4,32C22.9,64,46,128,69,128C91.4,128,114,64,137,69.3C160,75,183,149,206,181.3C228.6,213,251,203,274,202.7C297.1,203,320,213,343,208C365.7,203,389,181,411,149.3C434.3,117,457,75,480,96C502.9,117,526,203,549,213.3C571.4,224,594,160,617,149.3C640,139,663,181,686,213.3C708.6,245,731,267,754,234.7C777.1,203,800,117,823,90.7C845.7,64,869,96,891,144C914.3,192,937,256,960,250.7C982.9,245,1006,171,1029,149.3C1051.4,128,1074,160,1097,186.7C1120,213,1143,235,1166,234.7C1188.6,235,1211,213,1234,181.3C1257.1,149,1280,107,1303,80C1325.7,53,1349,43,1371,42.7C1394.3,43,1417,53,1429,58.7L1440,64L1440,320L1428.6,320C1417.1,320,1394,320,1371,320C1348.6,320,1326,320,1303,320C1280,320,1257,320,1234,320C1211.4,320,1189,320,1166,320C1142.9,320,1120,320,1097,320C1074.3,320,1051,320,1029,320C1005.7,320,983,320,960,320C937.1,320,914,320,891,320C868.6,320,846,320,823,320C800,320,777,320,754,320C731.4,320,709,320,686,320C662.9,320,640,320,617,320C594.3,320,571,320,549,320C525.7,320,503,320,480,320C457.1,320,434,320,411,320C388.6,320,366,320,343,320C320,320,297,320,274,320C251.4,320,229,320,206,320C182.9,320,160,320,137,320C114.3,320,91,320,69,320C45.7,320,23,320,11,320L0,320Z"></path>
            </svg>
        </div>
    </div>
</header>

