<section class="container">
    <div class="container-div">
        <img src="/assets/img-sfco/ilustracion-header.png" alt="">
    </div>
    <div class="container-div-card">
        <h2>Save your time</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus aliquid incidunt minima dolor labore, fugit aspernatur id eveniet sequi provident voluptatem animi eaque dolores? Alias earum atque odio sit voluptates.</p>
        <button>Donloawd App</button>
    </div>
</section>
