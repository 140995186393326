import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-sfco-header',
  templateUrl: './sfco-header.component.html',
  styleUrls: ['./sfco-header.component.css']
})
export class SfcoHeaderComponent implements OnInit {

  constructor( ) {
  }

  ngOnInit(): void {
  }

}
