import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SfcoHeaderComponent } from './sfco-header/sfco-header.component';
import { SfcoSectionUnoComponent } from './sfco-section-uno/sfco-section-uno.component';
import { SfcoSectionDosComponent } from './sfco-section-dos/sfco-section-dos.component';
import { SfcoSectionTresComponent } from './sfco-section-tres/sfco-section-tres.component';
import { SfcoSectionCuatroComponent } from './sfco-section-cuatro/sfco-section-cuatro.component';
import { SfcoSectionCincoComponent } from './sfco-section-cinco/sfco-section-cinco.component';
import { SfcoFooterComponent } from './sfco-footer/sfco-footer.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    SfcoHeaderComponent,
    SfcoSectionUnoComponent,
    SfcoSectionDosComponent,
    SfcoSectionTresComponent,
    SfcoSectionCuatroComponent,
    SfcoSectionCincoComponent,
    SfcoFooterComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
