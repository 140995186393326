<section class="container">
    <div class="container-uno">
        <h2>Titulo principal</h2>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error culpa animi veniam esse ad? Unde, corrupti quas. Corporis porro officiis laboriosam cumque quaerat voluptatibus unde at, quos mollitia omnis consequatur.</p>
    </div>
    <div class="container-dos">
        <div>
            <span>1</span>
            <h2>$25000</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <button>Plan 1</button>
        </div>
        <div>
            <span>2</span>
            <h2>$50000</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <button>Plan 2</button>
        </div>
    </div>
</section>
