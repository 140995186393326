<section class="container">
    <div class="container-div">
        <span class="icon-pacman"></span>
        <h2>{{'DEVELOPMENT' | translate}}</h2>
        <p>
           {{'ABOUT_DEVELOPMENT' | translate}}
        </p>
    </div>
    <div class="container-div container-div-especial">
        <span class="icon-user-tie"></span>
        <h2>{{'CONSULTING' | translate}}</h2>
        <p>
           {{'ABOUT_CONSULTING' | translate}}
        </p>
    </div>
    <div class="container-div">
        <span class="icon-star-empty"></span>
        <h2>{{'STARTUP_PLAN' | translate}}</h2>
        <p>
            {{'ABOUT_STARTUP_PLAN' | translate}}   
        </p>
    </div>
</section>
