import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sfco-footer',
  templateUrl: './sfco-footer.component.html',
  styleUrls: ['./sfco-footer.component.css']
})
export class SfcoFooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  switchLang(lang: string) {
  }

}
