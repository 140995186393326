<!DOCTYPE html>
<html lang="es">
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
  </head>
  <body>
    <main>
      <div id="headerPage">
        <app-sfco-header></app-sfco-header>
      </div>
      <div >
        <app-sfco-section-uno></app-sfco-section-uno> 
      </div>
      <!--div class="seccion">
        <app-sfco-section-dos></app-sfco-section-dos>
      </div -->
      <!--div class="seccion">
        <app-sfco-section-tres></app-sfco-section-tres>
      </div>
      <div class="seccion">
        <app-sfco-section-cuatro></app-sfco-section-cuatro>
      </div -->
      <div class="seccion">
        <app-sfco-section-cinco></app-sfco-section-cinco>
      </div>
      <div class="seccion">
        <app-sfco-footer></app-sfco-footer>
      </div>
      <a href="#headerPage" id="btnArriba"><span class="icon-arrow-up"></span></a>
    </main>
  </body>
</html>