<footer class="container" id="contact">
    <div class="container-logos">
        <div class="logos-logos">
            <p class="logo-w"><a target="_blank" href="https://wa.link/wsha98">WhatsApp <span class="icon-whatsapp"></span></a></p>
        </div>
        <p class="logo-link">www.fabioarias.co</p> 
    </div>
    <!-- div class="container-info">
        <div>
            <h3>About Us</h3>
            <ul>
                <li>Home</li>
                <li>Contact</li>
                <li>Blog</li>
                <li>Nosotros</li>
            </ul>
        </div>
        <div>
            <h3>About Us</h3>
            <ul>
                <li>Home</li>
                <li>Contact</li>
                <li>Blog</li>
                <li>Nosotros</li>
            </ul>
        </div>
        <div>
            <h3>About Us</h3>
            <ul>
                <li>Home</li>
                <li>Contact</li>
                <li>Blog</li>
                <li>Nosotros</li>
                <li>{{'Sitetitle' | translate }}</li>
            </ul>
        </div>
        <div>
            <!-- select 
                class="form-control" 
                #selectedLang 
                (change)="switchLang(selectedLang.value)">
                <option *ngFor="let language of translate.getLangs()" 
                    [value]="language"
                    [selected]="language === translate.currentLang">
                    {{ language }}
                </option>
            </select>
        </div>
    </div -->
</footer>
