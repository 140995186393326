import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sfco-section-uno',
  templateUrl: './sfco-section-uno.component.html',
  styleUrls: ['./sfco-section-uno.component.css']
})
export class SfcoSectionUnoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
