import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sfco-section-tres',
  templateUrl: './sfco-section-tres.component.html',
  styleUrls: ['./sfco-section-tres.component.css']
})
export class SfcoSectionTresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
