<section class="container" id="team">
    <nav class="container-nav">
        <h2>What 400+ reviews say abouts us</h2>
        <div>
            <span class="icon-undo"></span>
            <span class="icon-redo"></span>
        </div>
    </nav>
    <div class="container-div">
        <div class="container-div-card">
            <div class="card-title">
                <h2>German</h2>
                <div>
                    <span class="icon-cool"></span>
                    <span class="icon-cool"></span>
                    <span class="icon-cool"></span>
                    <span class="icon-cool"></span>
                    <span class="icon-cool"></span>
                </div>
            </div>
            <p>Excelente desarrollador</p>
            <p class="p-azul">
               Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur adipisci dolor veniam repellat eum architecto saepe enim. Distinctio possimus tempora reiciendis modi ea voluptates officiis quas tempore culpa voluptatum! Consequatur.
            </p>
        </div>
    </div>
</section>
